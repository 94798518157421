<template>
    <div v-if="!$route.meta.hideNavbar">
      <nav-bar/>
      <div style="margin-top: 120px;">
        <router-view class="mt-5"/>
      </div>
    </div>
    <div v-else>
      <router-view class="mt-5"/>
    </div>
    <ModalSignatureRequired :visibleModalSignatureRequired="isModalSignatureRequiredOpen" @close="closeModalSignatureRequiredVerify"> </ModalSignatureRequired>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
</style>

<script>
import ModalSignatureRequired from "@/components/ModalSignatureRequired.vue";
import NavBar from "@/components/Navbar.vue";
import {mapGetters} from "vuex";
import {mapActions} from 'vuex';
import router from "@/routers";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

export default {
  name: 'App',
  components: {
    NavBar,
    ModalSignatureRequired
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
    ...mapGetters(['isModalSignatureRequiredOpen']),
  },
  setup() {
    window.Hyperflow.init(process.env.VUE_APP_TOKEN_CHAT);
    router.beforeEach((to, from, next) => {
      if (from.name === 'Report') {
        window.Hyperflow.initFlow('2WSfobCky')
        try {
          window.Hyperflow.close()
        } catch (er) {
          console.log("Erro ao fechar o chat");
        }
      }
      next()
    })
  },
  data() {
    return {
      isModalSignatureRequiredVisible: false,
      modalInterval: null,
    };
  },
  mounted() {
    this.modalInterval = setInterval(() => {
      this.openModalSignatureRequiredVerify();
    }, 180000);
    this.User.specialist.specialties_info.map(function (specialty) {
      window.Echo.channel('specialty_queue.' + specialty.id).listen("NewCaseCreated", function () {
        window.toast.success('Um novo caso entrou na fila.', {
          timeout: null,
          closeOnClick: false
        });
      }.bind(this));
    })
  },
  beforeUnmount() {
    clearInterval(this.modalInterval);
  },
  created() {
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.VUE_APP_PUBLIC_REVERB_APP_KEY,
      wsHost: process.env.VUE_APP_PUBLIC_REVERB_HOST,
      wsPort: process.env.VUE_APP_PUBLIC_REVERB_PORT,
      wssPort: process.env.VUE_APP_PUBLIC_REVERB_PORT,
      forceTLS: (process.env.VUE_APP_PUBLIC_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
    });
  },
  methods: {
    ...mapActions(['closeModalSignatureRequired']),
    ...mapActions(['openModalSignatureRequired']),
    async closeModalSignatureRequiredVerify() {
      await this.closeModalSignatureRequired()
      this.modalInterval = setInterval(() => {
        this.openModalSignatureRequiredVerify();
      }, 180000);
    },
    async openModalSignatureRequiredVerify() {
      const dateActual = new Date();
      if (this.User.user_cess_access_info != null) {
        const dateExpired = new Date(this.User.user_cess_access_info.expired_at);
        if (dateActual > dateExpired) {
          await this.openModalSignatureRequired()
          clearInterval(this.modalInterval);
        }
      }
    }
  }
}
</script>