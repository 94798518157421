<template>
  <div class="container">
    <ModalPendency :visibleModalPendency="isModalPendencyVisible" @close="closeModalPendency"
      :reportUnsigned="reportUnsigned"> </ModalPendency>
    <div class="conteiner mt-5 mb-5">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <div class="search-bar">
            <input v-model="inputValueSearch" type="search" class="form-control text-font-regular background-search"
              placeholder="Buscar" aria-label="Search" aria-describedby="search-addon"
              @keydown.enter.prevent="handleChangeSearch"/>
            <div class="clear-button"></div>
            <button class="search-button" @click="handleChangeSearch">
              <img src="../assets/search-icon-white.svg" alt="Ícone de pesquisa">
            </button>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
    <div class="conteiner mt-5 mb-5">
      <div class="row mt-5">
        <div class="col-1"></div>

          <div class="filter-container">
              <input type="checkbox" id="filterAll" v-model="filterAllSpecialties" @change="retrieveHistorics" class="custom-checkbox">
              <label for="filterAll" class="custom-label">Pesquisar em todas as especialidades</label>
          </div>

          <div class="col-10">
          <div>
            <h4 class="text-font me-3">Histórico</h4>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
      <div v-if="isLoading === true">
        <div class="conteiner mt-5 mb-5">
          <div class="row mt-5">
            <div class="row mt-4">
              <div class="col-2"></div>
              <div class="col-8">
                <div class="centered-container">
                  <div class="loading"></div>
                </div>
                <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                  Carregando...
                </p>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="verifyHistorics()">
          <ul style="list-style-type: none">
            <li v-for="historic in HistoricosResult.reports_info" v-bind:key="historic.id">
              <div class="row mt-4">
                <div class="col-1"></div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body card-border-unavailable">
                      <h5 class="card-title setup-text-card-title">
                        {{ historic.protocol_update_info.protocol_info.company }}
                      </h5>
                      <p class="card-text setup-text-card">
                          Especialidade:
                          <b style="color:#3F9CA6;">{{ formatName(historic.protocol_update_info.protocol_info.specialty) }}</b>
                      </p>
                      <p class="card-text setup-text-card">
                        Responsável:
                        {{ formatName(historic.protocol_update_info.responsible_info.name) }}
                      </p>
                      <p class="card-text setup-text-card">
                        Paciente:
                        {{ formatName(historic.protocol_update_info.protocol_info.patient_info.name) }}
                      </p>
                      <p class="card-text setup-text-card">
                        Documento:
                        {{ formatName(historic.protocol_update_info.protocol_info.patient_info.document) }}
                      </p>
                      <p class="card-text setup-text-card">
                        Tipo: {{ historic.protocol_update_info.case_type }}
                      </p>
                      <p class="card-text setup-text-card">
                        Local: {{ historic.protocol_update_info.case_location }}
                      </p>
                      <p class="card-text setup-text-card">
                        Prioridade: {{ historic.protocol_update_info.case_priority }}
                      </p>
                      <p class="card-text setup-text-card">
                        Especialista: {{ historic.specialist_info.name }}
                      </p>
                      <p class="card-text setup-text-card">
                        Data de emissão: {{ historic.protocol_update_info.created_at }}
                      </p>
                      <div v-if="verifyOwner(historic)">
                        <div v-if="findUpdatedAtForStep4(historic.statuses) != null">
                          <p class="card-text setup-text-card">
                            Laudo assinado digitalmente dia {{ findUpdatedAtForStep4(historic.statuses) }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="card-text setup-text-card">
                            <img src="../assets/alert-triangulo.png">
                            Assinatura pendente
                          </p>
                        </div>
                      </div>

                      <div class="mt-4"></div>
                      <div class="d-flex justify-content-between">
                        <a></a>
                        <button class="btn btn-secondary_pep" style="text-align: right" @click="openReview(historic)">
                          VER CASO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
              </div>
            </li>
          </ul>
          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-center">
              <div class="overflow-auto">
                <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" prev-text="Anterior"
                  next-text="Próximo"></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row mt-4">
            <div class="col-2"></div>
            <div class="col-8">
              <p class="text-font-regular" style="text-align: center">
                Não há casos em histórico.
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clear-button {
  margin-left: 44px;
}

.backgound-imagem-search {
  background-color: #6200EE;
  max-width: 200px;
  max-height: 150px;
  width: auto;
  height: auto;
}

.setup-text-card-title {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.setup-text-card {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.btn-secondary_pep {
  background-color: #ffffff;
  color: #6200ee;
  padding: 0;
  border: none;
  background: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.card-border-unavailable {
  border-left: 8px solid #bebebe;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  position: relative;
}

.search-button {
  height: 36px;
  width: 40px;
  background-color: #6200EE;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.search-button img {
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
  object-fit: cover;
}

.search-bar i {
  margin-right: 10px;
}

.search-bar input[type="search"] {
  flex: 1;
  border: none;
  outline: none;
}

.background-search {
  background-color: #f0f0f0;
}

.text-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}


.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
    display: flex;
    align-items: center;
    border: 1px solid #bdbdbd;
    border-radius: 20px;
    padding: 8px 16px;
    width: fit-content;
    margin: 0 0 15px 6px;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    cursor: pointer;
}

.custom-label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #777777;
    cursor: pointer;
}

</style>

<script>
import ModalPendency from "../components/ModalPendency.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Historical`,
  components: {
    ModalPendency
  },
  async created() {
    if (!this.isAuthenticated()) {
        this.$router.push("/login");
        return;
    }

    this.isLoading = true;

    try {
        await this.GetReportsUnsigned();
        await this.GetReports();

        const fetchHistorics = async () => {
            const params = {
                filters: {
                    title: "",
                    all_specialties: false,
                    finished: 1
                },
                page: this.page,
                limit: this.pageSize
            };

            return await this.GetHistorics(params);
        };

        this.HistoricosResult = await fetchHistorics();
        this.count = this.HistoricosResult.total;

        if (this.HistoricosResult?.reports_info.length) {
            this.checkAllSpecialties = true;
        }

        this.HistoricosResult = await fetchHistorics(this.checkAllSpecialties);

        this.isLoading = false;

    } catch (error) {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
            timeout: 3000,
            closeOnClick: true
        });
        this.isLoading = false;
    }
  },
  async mounted() {
    if(this.ReportsUnsigned.length > 0){
      this.reportUnsigned = this.ReportsUnsigned[0]
      this.showModalPendency(this.reportUnsigned)
    }
  },
  computed: {
    ...mapGetters({ Reports: "StateReports", ReportsUnsigned: "StateReportsUnsigned",  User: "StateUser" }),
  },
  watch: {
    async page(newPage, oldPage) {
      if (newPage !== oldPage) {
        await this.retrieveHistorics();
      }
    },
    filterAllSpecialties(newValue) {
      if (!newValue) {
          this.inputValueSearch = "";
          this.retrieveHistorics();
      }
    },
  },
  data() {
    return {
      checkAllSpecialties: null,
      isModalPendencyVisible: false,
      HistoricosResult: null,
      isLoading: true,
      inputValueSearch: null,
      reportUnsigned: Object,
      page: 1,
      count: 1,
      pageSize: 10,
      filterAllSpecialties: false,
    };
  },
  methods: {
    ...mapActions(["GetReportById"]),
    ...mapActions(["GetReports"]),
    ...mapActions(["GetReportsUnsigned"]),
    ...mapActions(["GetHistorics"]),
    ...mapGetters(["isAuthenticated"]),
    async clearSearch() {
      this.page = 1;
      this.inputValueSearch = ""
      await this.retrieveHistorics();
    },
    showModalPendency(paramReportUnsigned) {
      this.reportUnsigned = paramReportUnsigned;
      this.isModalPendencyVisible = true;
    },
    closeModalPendency() {
      this.isModalPendencyVisible = false;
    },
    verifyOwner(historic) {
      if (this.User.specialist.id == historic.specialist_info.id) {
        return true;
      }
      return false;
    },
    verifyHistorics() {
      if (this.HistoricosResult != null) {
        if (this.HistoricosResult.reports_info.length > 0) {
          return true;
        }
      }
      return false;
    },
    formatPhone(phone) {
      if (phone != null) {
        const ddd = phone.substring(2, 4);
        const first = phone.substring(4, 9);
        const second = phone.substring(9);
        return `${ddd} ${first}-${second}`;
      }
    },
    formatName(name) {
      if (name != null) {
        let words = name.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      }
    },
    async openReview(report) {
      await this.GetReportById(report.id);
      this.$router.push({ name: 'HistoricalReview' });
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};
      params = {
        filters: {
          title: searchTitle,
          finished: 1
        },
        page: page,
        limit: pageSize
      }
      return params;
    },
    async retrieveHistorics() {
      let checkAllSpecialties = null;

      if (this.filterAllSpecialties) {
          checkAllSpecialties = true;
      } else {
          checkAllSpecialties = false;
      }

      const params = {
          filters: {
              title: this.inputValueSearch || "",
              all_specialties: checkAllSpecialties,
              finished: 1,
          },
          page: this.page,
          limit: this.pageSize,
      };

      this.isLoading = true;

      try {
          const historicosResult = await this.GetHistorics(params);
          this.HistoricosResult = historicosResult;
          this.count = historicosResult.total;
      } catch (error) {
          this.$toast.error("Ocorreu um erro, tente novamente.", {
              timeout: 3000,
              closeOnClick: true,
          });
      } finally {
          this.isLoading = false;
      }
    },
    async handleChangeSearch() {
      this.page = 1;
      await this.retrieveHistorics();
    },
    findUpdatedAtForStep4(statuses) {
      const status = statuses.find((status) => status.step === 4);
      if (status) {
        if(status.status == "success"){
          return this.formatUpdatedAt(status.updated_at);
        }
        return null;
      } else {
        return null;
      }
    },
    formatUpdatedAt(updated_at) {
      const date = new Date(updated_at);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
};
</script>
